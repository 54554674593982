
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Arial;
}

.header {
    text-align: center;
    padding: 32px;
}

.row-2 {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap;
    /* IE10 */
    flex-wrap: wrap;
    padding: 4px;
}

/* Create four equal column-2s that sits next to each other */
.column-2 {
    -ms-flex: 50%;
    /* IE10 */
    flex: 50%;
    max-width: 50%;
    padding: 4px;
    margin-bottom: 10px;
}

.column-2 img video-view {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}

/* Responsive layout - makes a two column-2-layout instead of four columns */
@media screen and (max-width: 800px) {
    .column-2 {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
        /* margin-bottom: 10px; */
    }
}

/* Responsive layout - makes the two column-2s stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column-2 {
        display: contents;
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        height: 120px;
        margin-bottom: 10px;
        /* max-height: 60px; */
    }
}