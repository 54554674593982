.bookreview-container { 
    display: flex;
    /* max-height: 200px; */
    margin: auto;
}

.bookreview-content {
    flex: 1;
    /* padding: 20px; */

}

.bookreview-image {
    /* flex: 1; */
    width: 200px;
    /* padding: 20px; */
}

.bookreview-image img {
width: 100%;
height: 200px;
object-fit: contain;
}

.bookreview-container a { 
    color: white;
}