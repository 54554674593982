body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link {
  padding: 5px;
  font-size: xx-large;
  color: white;
  /*text-decoration: none;*/
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: #2c2c2c;
  /* min-height: 100%; */
}

router-link {
  color: black;
  /*display: block;*/
}

html {
  background: #2c2c2c;
  margin: 0;
  /* width: 100%; */
  /* min-height: 100vh; */
}

body {
  background-color: #2c2c2c;
}

navigation {
  display: inline-flex;
}
