.blog-container{
    /* text-align: center; */
    max-width: 800px;
    color: white;
    margin: auto;
}

.blog-container h1 {
    text-align: center;
    color: white;
    font-size: 30px;
}


.blog-container h2 {
    color: white;
    font-weight: bolder;
    font-size: 20px;
}



.blog-container a {
    color: white;
    font-size: 20px;
    text-decoration: underline;
}

.blog-container li {
    padding-top: 5px;
}
/* .blog-container ul {
    max-width: 800px;
} */
/* 
.blog-container ul {
    max-width: 800px;
    list-style: none; /* Remove default list styles (optional) */
    /* padding: 0; Remove default padding on the ul element */
    /* margin: 0; Remove default margin on the ul element */
    /* display: inline-block; Make the ul display as an inline block */
/* } */


.blog-container li {
    text-align: left;
}