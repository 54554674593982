.tagcloud {
  margin-top: -4px;
  padding-inline-start: initial;
}

.tagcloud-li {
  display: inline-block;
  font-size: medium;
}

.navigation-title {
  margin: auto;
  text-align: center;
  /* padding-left: 11px; */
}

.main-navigation {
    margin: 10px;
}

.navigation-container {
  clear: both;
  padding: 16px 16px 16px 16px;
  border-radius: 10px;
  margin: 16px auto;
  border: solid 1px;
  text-align: center;
  max-width: 1224px;
  border-style: dashed;
  list-style: none; 
}