.tools-container{
    /* text-align: center; */
    max-width: 800px;
    color: white;
    margin: auto;
    
}

.tools-container h1 {
    text-align: center;
    color: white;
    font-size: 30px;
}


.tools-container h2 {
    color: white;
    font-weight: bolder;
    font-size: 20px;
}



.tools-container a {
    color: white;
    font-size: 20px;
    text-decoration: underline;
}

.tools-container li {
    padding-top: 5px;
}
/* .tools-container ul {
    max-width: 800px;
} */
/* 
.tools-container ul {
    max-width: 800px;
    list-style: none; /* Remove default list styles (optional) */
    /* padding: 0; Remove default padding on the ul element */
    /* margin: 0; Remove default margin on the ul element */
    /* display: inline-block; Make the ul display as an inline block */
/* } */


.tools-container li {
    text-align: left;
}