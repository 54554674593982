.job-experience-container {
    border: solid 1px;
    border-radius: 10px;
    border-color: white;
    margin: 10px;
    padding: 10px;
    /* max-width: 50%; */
    /* background-color: yellow; */
    display: flex;
}

.experience-container {
    padding-left: 16px;
}

.job-left-content {
  flex-grow: 1;
  /* flex: 1; Take up remaining space */
  overflow: hidden;
  padding-right: 20px; /* Add some spacing between left and right content */
}

.job-right-content {
  flex-shrink: 0; /* Do not shrink the right content */
}

.job-image {
    height: 150px;
    width: 150px;
    background-color: blue;
    color: blue;
    float: right;
    align-self: flex-end; /* Align the image to the end of the flex container */
}

.a {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
    .job-image {
        width: 200px;
        height: 200px;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .job-image {
        width: 50px;
        height: 50px;
    }
  }