.Article-container {
    margin: auto;
    max-width: 800px;
    font-size: 20px;
}

.Article-container h1 {
    text-align: center;
    color: white;
    font-size: 30px;
}


.Article-container h2 {
    color: white;
    font-weight: bolder;
    font-size: 20px;
}

.Article-container p {
    color: white;
}

.Article-container hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.Article-container table, th, td {
    border:1px solid white;
    color: white;
  }


@media screen and (max-width: 800px) {
.Article-container {
    padding: 20px;
}
}