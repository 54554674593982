.video-id {
    /* text-align: left;  */
    display: flex;
    height: 650px;
    width: 100%;
    margin-bottom: 10px;
    /* padding-bottom: 10px; */
}

.video-iframe {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 800px) {
    .video-id {
        height: 450px;
    }
}

/* Responsive layout - makes the two column-2s stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .video-id {
        height: 350px;
    }
}