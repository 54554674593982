/* .socialbtns {
} */
.socialmediaicon {
    width: 50px;
    cursor: pointer;
    height: 50px;
    padding: 8px;
}

.socialmedia-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333333;
}

.socialmedia-li {
    float: left;
    display: contents;
    margin: 8px;
}